type NavLink = {
  title: string
  href: string
  target?: string
  page?: string
  onClick?: () => void
  redirect?: string
}

export type NavLinkCollection = {
  title: string
  href?: string
  items: NavLink[]
}

export type NavNode = NavLink | NavLinkCollection

export const isNavLinkCollection = (
  node: NavNode
): node is NavLinkCollection => {
  const asLinkCollection = node as NavLinkCollection
  return Boolean(asLinkCollection.title && asLinkCollection.items)
}

export const NAV_STRUCTURE: NavNode[] = [
  {
    title: 'Search',
    items: [
      {
        href: '/listings/search',
        title: 'Listings',
        page: '/listings/search/[...params]',
      },
      { href: '/listings/mls', title: 'MLS Number' },
      { href: '/listings/open-houses', title: 'Open Houses' },
      { href: '/listings/rentals', title: 'Rentals' },
      { href: '/listings/realtors', title: 'REALTORS®' },
    ],
  },
  {
    title: 'About NGLRMLS',
    items: [
      { href: '/about/about-nglrmls', title: 'About Us' },
      { href: '/about/how-to-participate', title: 'How to Participate' },
      { href: '/about/why-regionalize', title: 'Why Regionalize' },
      {
        href: '/about/advertising-on-nglrmls',
        title: 'Advertising on NGLRMLS',
      },
      {
        href: '/assets/pdf/NGLRMLS%20Website%20Privacy%20Policy%207-2-2018.pdf',
        title: 'Privacy Policy',
        target: '_blank',
      },
    ],
  },
  {
    title: 'MLS Resources',
    items: [
      {
        href: '/resources/membership-applications',
        title: 'Membership Applications',
      },
      {
        href: '/resources/non-member-mls-forms',
        title: 'Non-member MLS Forms',
      },
      {
        href: '/assets/pdf/Co-mingling_Policy_01-27-25.pdf',
        title: 'IDX Co-mingling Policy',
        target: '_blank',
      },
    ],
  },
  {
    title: 'Contact',
    href: '/contact',
  },
  {
    title: 'Members',
    href: '/members/login',
    redirect: '/members/search-stats',
  },
]

export const FOOTER_STRUCTURE = Object.values(
  NAV_STRUCTURE.reduce<Record<string, NavLinkCollection>>(
    (acc, curr) =>
      isNavLinkCollection(curr)
        ? { ...acc, [curr.title]: curr }
        : {
            ...acc,
            Other: {
              title: 'Other',
              items: [...(acc['Other']?.items || []), curr],
            },
          },
    {}
  )
)
